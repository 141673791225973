import {Controller} from "stimulus"
import StimulusReflex from "stimulus_reflex";
import $ from "jquery";

import 'select2';
import 'select2/dist/css/select2.css';

export default class extends Controller {
  static values = {
      selector: String,
      dropdownParent: String,
      apiUrl: String,
      refresh: Number,
      reflexAction: String
  }
  connect() {
    let controller                = this
    this.element[this.identifier] = this
    StimulusReflex.register(controller)

    $(document).ready(function() {
      let config = {}
      if(controller.dropdownParentValue){
        config['dropdownParent'] = controller.dropdownParentValue
      }
      if(controller.apiUrlValue)
        config['ajax'] = {
          url: controller.apiUrlValue,
          data: function(data) {
            return { name: data.term }
          }
        }
      $(controller.selectorValue).select2(config);

      if(controller.reflexActionValue){
        $(controller.selectorValue).on('select2:select', function (e) {
          controller.stimulate(controller.reflexActionValue, e.target)
        });
      }
    });
  }

  refreshValueChanged() {
    let controller = this
    if (this.refreshValue === 1) {
      $(document).ready(function() {
        let config = {}
        if(controller.dropdownParentValue){
          config['dropdownParent'] = controller.dropdownParentValue
        }
        if(controller.apiUrlValue)
          config['ajax'] = {
            url: controller.apiUrlValue,
            data: function(data) {
              return { name: data.term }
            }
          }
        $(controller.selectorValue).select2(config);
      });
      this.refreshValue = 0
    }
  }
}
