import {Controller} from 'stimulus'
import StimulusReflex from "stimulus_reflex";

export default class extends Controller {
    connect() {
        StimulusReflex.register(this)
    }
    link_bank_account() {
        let controller = this
        let ACCOUNT_TYPES = { 'depository': 'debit', 'credit': 'credit' }

        const plaidCreate = (link_token) => {
            console.log('TOKEN', link_token)
            let handler = Plaid.create({
                token: link_token,
                onSuccess: (public_token, metadata) => {
                    plaidSuccess(public_token, metadata)
                }
            })
            handler.open()
        }

        const plaidSuccess = (public_token, metadata) => {
            let accountForm = "payrix_bank_account_"
            $(`#${accountForm}plaid_account_type`).val(ACCOUNT_TYPES[metadata.account.type] || 'all')
            $(`#${accountForm}plaid_complete`).val(true)
            $(`#${accountForm}plaid_public_token`).val(public_token)
            $(`#${accountForm}plaid_account_id`).val(metadata.account_id)
            controller.stimulate('Backend::Payrix#update_invite', document.getElementById('payrix_bank_account_plaid_account_id'))
        }

        const createToken = () => {
            return $.get('/setup/create_link_token').done(response =>
                plaidCreate(response.link_token)
            )
        }
        createToken()
    }
}