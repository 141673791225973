import {Controller} from "stimulus"

export default class extends Controller {
    static targets = ["input", "form", "button"]

    connect() {
        this.element[this.identifier] = this
    }

    submit() {
        this.formTarget.submit()
    }

    upload() {
        this.inputTarget.click()
    }
}
