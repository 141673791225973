export function getMetaValue(name) {
    const element = findElement(document.head, `meta[name="${name}"]`);
    if (element) {
        return element.getAttribute("content");
    }
}

export function findElement(root, selector) {
    if (typeof root == "string") {
        selector = root;
        root = document;
    }
    return root.querySelector(selector);
}

export function toArray(value) {
    if (Array.isArray(value)) {
        return value;
    } else if (Array.from) {
        return Array.from(value);
    } else {
        return [].slice.call(value);
    }
}

export function removeElement(el) {
    if (el && el.parentNode) {
        el.parentNode.removeChild(el);
    }
}

export function insertAfter(el, referenceNode) {
    return referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
}

export function anchor_include(string) {
    return anchor_to_array().includes(string)
}

export function anchor_add(string) {
    if (anchor_include(string)) {
        return
    }

    let array = anchor_to_array()
    array.push('_'+string)
    history.pushState(null, document.title, window.location.pathname + '#' + array.join(','));
}

export function anchor_remove(string) {
    const array = anchor_to_array()
    const index = array.indexOf(string);
    if (index > -1) {
        array.splice(index, 1);
    }
    if (array.length > 0) {
        history.pushState(null, document.title, window.location.pathname + '#' + array.join(','));
    } else {
        history.pushState(null, document.title, window.location.pathname);
    }
}

export function anchor_to_array() {
    return window.location.hash.substring(1).split(',').filter(String).map(s => s.substring(1))
}