import {Controller} from 'stimulus'
import StimulusReflex from "stimulus_reflex";

export default class extends Controller {
    static values = {
        doNotShow: Boolean,
        reflexOnClose: String,
        reflexOnOpen: String,
        close: Number,
        showModal: Number,
    }

    connect() {
        StimulusReflex.register(this)
        this.modal = new bootstrap.Modal(this.element, {
            keyboard: false
        })
        if(this.doNotShowValue) return;

        this.show()
    }

    show() {
        this.modal.show()
        if (this.reflexOnOpenValue !== '') {
            this.stimulate(this.reflexOnOpenValue)
        }
    }

    closeValueChanged() {
        if (this.closeValue === 1) {
            this.close()
        }
    }

    showModalValueChanged() {
        if (this.showModalValue === 1) {
            this.show()
        }
    }

    close() {
        if (this.reflexOnCloseValue !== '') {
            this.stimulate(this.reflexOnCloseValue)
        }
        this.element.remove();
    }

    disconnect() {
        this.modal.hide()
    }
}