import {Controller} from 'stimulus'
import StimulusReflex from "stimulus_reflex";

export default class extends Controller {
    static targets = ['document'];
    static values = {
        reviewAll: Number,
        refreshDocuments: Number,
        closeDocument: Number,
    }

    connect() {
        let controller = this
        this.element[this.identifier] = this
        StimulusReflex.register(controller)

        this.listener = () => {
            this.openDefaultDocument()
        }

        if (this.isActionCableConnectionOpen()) {
            this.openDefaultDocument()
        } else {
            document.addEventListener('stimulus-reflex:connected', this.listener)
        }
    }

    refreshDocumentsValueChanged() {
        if (this.refreshDocumentsValue !== 1) return
        this.refreshDocumentsValue = 0

        this.stimulate('Backend::DocumentEnvelopes::SignReflex#documents')
    }

    closeDocumentValueChanged() {
        console.log('Close Value: ', this.closeDocumentValue)
        if (this.closeDocumentValue !== 1) return
        this.closeDocumentValue = 0

        if (this.reviewAllValue === 1) {
            this.stimulate('Backend::DocumentEnvelopes::SignReflex#review_next')
        } else {
            this.stimulate('Backend::DocumentEnvelopes::SignReflex#close_iframe')
        }
    }

    disconnect() {
        window.removeEventListener('stimulus-reflex:connected', this.listener);
    }

    openDefaultDocument() {
        this.documentTargets.every(document => {
            if (document.dataset.default === '1') {
                this.openDocument(document)
                return false
            }
            return true
        })
    }

    review(event) {
        this.openDocument(event.target)
    }

    reviewVitalsForm(event) {
        this.openVitalsForm(event.target)
    }

    reviewAll(event) {
        this.reviewAllValue = 1
        this.stimulate('Backend::DocumentEnvelopes::SignReflex#review_all')
    }

    openDocument(target) {
        this.reviewAllValue = 0
        this.stimulate('Backend::DocumentEnvelopes::SignReflex#review', target)
    }

    openVitalsForm(target) {
        this.reviewAllValue = 0
        this.stimulate('Backend::DocumentEnvelopes::SignReflex#review_vitals_form', target)
    }
}