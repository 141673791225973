import {Controller} from "stimulus"
import StimulusReflex from "stimulus_reflex";

export default class extends Controller {
    static values = {event: String}

    connect() {
        StimulusReflex.register(this)
    }

    call(event) {
        event.preventDefault()
        this.stimulate(this.eventValue, this.element)
    }
}