import { Controller } from 'stimulus'
import StimulusReflex from 'stimulus_reflex'
import Cohere from "cohere-js";

export default class extends Controller {
    connect() {
        Cohere.init(this.element.getAttribute('data-token'));
        Cohere.identify(
            this.element.getAttribute('data-user-id'), {
            displayName: this.element.getAttribute('data-user-name'),
            email: this.element.getAttribute('data-email'),
        })
    }
}