import {Controller} from 'stimulus'

export default class extends Controller {
    static values = {
        location: String,
        defaultLocation: String,
    }

    locationValueChanged() {
        if (this.locationValue === '') return
        if (this.locationValue === '1' && this.defaultLocationValue === '') return

        document.location = this. locationValue === '1' ? this.defaultLocationValue : this.locationValue
    }
}
