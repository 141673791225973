import {
  Controller
} from 'stimulus'
import StimulusReflex from 'stimulus_reflex'

export default class extends Controller {

  static values = {
    refresh: Number,
    alert: String
  }

  showError(alert) {
    const alertData = JSON.parse(alert)
    Swal.fire({
      //icon: 'error',
      title: `<i class="bi bi-exclamation-triangle-fill bg-danger-10 d-flex justify-content-center align-items-center rounded-circle me-3"></i> Oops. Something went wrong. `,
      showConfirmButton: false,
      html: `<p class="fs-6-125">An error has occurred. Please copy and share with customer support at <a href="mailto:support@partingpro.com">support@partingpro.com</a> for assistance. <span class="fw-medium">"${alertData.body.replace(/(<([^>]+)>)/ig,"")}"</span></p>
       <p class="mb-0" style="text-align: right;"><a id="clipboard-error-button" data-clipboard-text="${alertData.body.replace(/(<([^>]+)>)/ig,"")}"
        class="btn btn-primary btn-sm fs-6-125" href="#">Copy error</a></p>`,
       didRender: this.didRenderAlert
     })
  }

  connect() {
    let controller = this
    this.element[this.identifier] = this
    StimulusReflex.register(controller)
  }

  didRenderAlert = () => {
    new ClipboardJS('#clipboard-error-button');
  }

  refreshValueChanged() {
    if (this.refreshValue === 1) {
      this.showError(this.alertValue)
      this.refreshValue = 0
    }
  }
}
