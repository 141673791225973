import {Controller} from "stimulus"
import StimulusReflex from "stimulus_reflex";
import autoComplete from "@tarekraafat/autocomplete.js";

export default class extends Controller {
    static values = {
        url: String,
        event: String,
        update: String,
        refresh: Number
    }
    static targets = ['input'];

    connect() {
        let controller = this
        this.element[this.identifier] = this
        StimulusReflex.register(controller)
    }

    disconnect() {
    }

    update(event) {
        this.stimulate(this.eventValue, event.target)
    }

    init_autocomplete() {
        let controller = this;
        this.inputTargets.forEach((el, index) => {
            if (el['autocomplete10'] === undefined) {
                el['autocomplete10'] = new autoComplete({
                    selector: `#${el.id}`,
                    data: {
                        src: async (query) => {
                            try {
                                const source = await fetch(`${controller.urlValue}?query=${query}`);
                                const data = await source.json();
                                return data;
                            } catch (error) {
                                return error;
                            }
                        },
                        keys: ['name'],
                    },

                    threshold: 1,
                    resultItem: {
                        highlight: "autoComplete_highlight",
                    },

                    events: {
                        input: {
                            selection: (event) => {
                                const selection = event.detail.selection.value;
                                event.target.value = selection.name;
                                event.target.setAttribute('data-place-id', selection.place_id)
                                this.refreshValue = 1
                                controller.stimulate(event.target.getAttribute('data-event-value'), event.target)
                            },
                            change: (event) => {
                                if (this.refreshValue === 0) {
                                    controller.stimulate(event.target.getAttribute('data-update-value').replace(/.*->/, ''), event.target)
                                }
                            }
                        }
                    }
                });
            } else {
                el['autocomplete10'].init();
            }

        })
    }

    refreshValueChanged() {
        if (this.refreshValue === 1) {
            this.init_autocomplete()
            this.refreshValue = 0
        }
    }
}
