import {Controller} from 'stimulus'
import StimulusReflex from 'stimulus_reflex';

export default class extends Controller {
    static values = {
        put: Boolean,
    }

    connect() {
        let controller = this
        this.element[this.identifier] = this
        StimulusReflex.register(controller)
        controller.sortable = new Sortable(
            this.element,
            {
                swapThreshold: 1,
                ghostClass: 'drag',
                animation: 150,
                filter: '.not-sortable',
                group: {
                    name: 'shared',
                    put: controller.putValue ? null : false,
                },
                onAdd: function (event) {
                    controller.sort(event)
                },
                onUpdate: function (event) {
                    controller.sort(event)
                },
            }
        )
    }

    sort(event) {
        event.item.dataset.sourceQuestionGroup = event.to.dataset.sourceQuestionGroup
        event.item.dataset.newQuestionsGroupUuid = event.to.dataset.questionsGroupUuid
        event.item.dataset.questionsGroupUuid = event.from.dataset.questionsGroupUuid || event.to.dataset.questionsGroupUuid
        event.item.dataset.rowOrder = event.to.dataset.rowOrder
        event.item.dataset.colOrder = event.newIndex
        console.log(event.item.dataset.colOrder)
        this.stimulate(
            'Backend::Vitals::QuestionReflex#sort',
            event.item,
        )
    }

    putValueChanged() {
        if (this.sortable !== undefined) {
            this.sortable.option(
                "group", {
                    name: 'shared',
                    put: this.putValue ? null : false,
                }
            )
        }
    }
}
