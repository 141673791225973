import {Controller} from "stimulus"
import StimulusReflex from "stimulus_reflex";
import AutoNumeric from "autonumeric";

export default class extends Controller {
    static targets = ['currency', 'percentage', 'ssn', 'posPercentage', 'numbers', 'posNumeric', 'zipCode'];
    static values = {event: String, conditionId: String, maximum: String, minimum: String}

    connect() {
        let controller = this
        this.element[this.identifier] = this
        StimulusReflex.register(controller)
        let data = [
            {watchExternalChanges: true, currencySymbol: '$'},
            'dotDecimalCharCommaSeparator'
        ]
        if (this.element.dataset.onlypositive === 'true') {
            data = [{watchExternalChanges: true, currencySymbol: '$', decimalPlacesRawValue: 2, minimumValue: 0},
                'dotDecimalCharCommaSeparator']
        }

        this.currencyTargets.forEach((el, index) => {
            if (el['AutoNumeric'] === undefined) {
                el['AutoNumeric'] = new AutoNumeric(el, data)
            }
        })

        this.percentageTargets.forEach((el, index) => {
            if (el['AutoNumeric'] === undefined) {
                el['AutoNumeric'] = new AutoNumeric(
                    el, [
                        {watchExternalChanges: true},
                        'percentageUS2dec'
                    ]
                )
            }
        })

        let options = {watchExternalChanges: true}
        if (controller.maximumValue !== '') {
            options.maximumValue = controller.maximumValue
        }
        if (controller.minimumValue !== '') {
            options.minimumValue = controller.minimumValue
        }

        this.numbersTargets.forEach((e, _) => {

            var cleave = new Cleave(e, {
                numericOnly: true,
                delimiters: ['-'],
                blocks: [2, 7],
                uppercase: true
            });
        })

        this.posNumericTargets.forEach((el, _) => {
            if (el['AutoNumeric'] === undefined) {
                el['AutoNumeric'] = new AutoNumeric(
                    el, [options]
                )
            }
        })

        this.posPercentageTargets.forEach((el, _) => {
            if (el['AutoNumeric'] === undefined) {
                el['AutoNumeric'] = new AutoNumeric(
                    el, [
                        options,
                        'percentageUS2decPos',
                    ]
                )
            }
        })

        this.ssnTargets.forEach((e, index) => {
            var cleave = new Cleave(e, {
                numericOnly: true,
                delimiters: ['-'],
                blocks: [3, 2, 4],
                uppercase: true
            });
        })


        this.zipCodeTargets.forEach((e, _) => {
            new Cleave(e, {
                numericOnly: true,
                delimiters: ['-'],
                blocks: [5],
                uppercase: true
            });
        })
    }

    disconnect() {
    }

    update(event) {
        this.stimulate(this.eventValue, event.target)
    }

    updateWithCondition(event) {
        let processCardEnabled = $(`#${this.conditionIdValue}`).is(':checked') ? 1 : 0;

        let form = event.target
        $("#processCardEnabledCondition").remove();
        let checkbox = document.createElement("input");
        checkbox.setAttribute('name', 'process_card_condition');
        checkbox.setAttribute('id', 'processCardEnabledCondition');
        checkbox.setAttribute('type', 'hidden');
        checkbox.setAttribute('value', processCardEnabled);
        form.appendChild(checkbox);

        this.stimulate(this.eventValue, form);
    }
}
