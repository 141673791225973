import { Controller } from "stimulus"
import StimulusReflex from "stimulus_reflex";
import $ from "jquery";

export default class extends Controller {
    static values  = {
        refresh: Number,
        menu: Number
    }

    connect() {
        this.element[this.identifier] = this
        StimulusReflex.register(this)
    }

    refreshValueChanged() {
        if (this.refreshValue === 1) {
            this.initialize_scroll_spy()
            this.refreshValue = 0
        }
    }

    initialize_scroll_spy() {
        $('.mobile-aside-nav .nav-link').click(function (e) {
            $('.mobile-aside-nav').collapse('toggle');
        });
    }

    menuValueChanged() {
        if (this.refreshValue === 1) {
            setTimeout(function() {
                var mobile_menu_mobile = $('#case-menu-mobile').is(":visible")
                var elems = $('.nav-link')
                if(mobile_menu_mobile == true) {
                    Array.prototype.forEach.call(elems, function(el) {
                        el.classList.remove("active");
                    });
                }
            }, 1500);
        }
    }
}
