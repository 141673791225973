import {Controller} from "stimulus"

export default class extends Controller {
  static values = { refresh: Number, elementIdentifier: String }

  connect() {
    let dropdawnMenuoOntroller = this
    this.element[this.identifier] = this
  }

  refreshValueChanged() {
    if(this.refreshValue === 1) {
      this.refreshValue = 0;
      new bootstrap.Dropdown(document.getElementById(this.elementIdentifierValue))
    }
  }
}
