import {Controller} from 'stimulus'
import StimulusReflex from 'stimulus_reflex'
import Cleave from 'cleave.js'
import $ from "jquery";
import warningatorPlugin from "../misc/warningator_plugin";
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

export default class extends Controller {
    static targets = ['select'];
    static values = {
        refresh: Number,
        warningator: Boolean,
    }

    connect() {
        this.element[this.identifier] = this
        StimulusReflex.register(this)
    }

    refreshValueChanged() {
        if (this.refreshValue === 1) {
            this.initialize_flatpickr()
            this.refreshValue = 0
        }
    }

    initialize_flatpickr() {
        let controller = this;
        this.selectTargets.forEach((el, index) => {
            if ($(el).data("datepickerTime") == '1') {
                let options = {
                    enableTime: true,
                    noCalendar: true,
                    dateFormat: "H:i",
                    time_24hr: true,
                    // allowInput: true,
                    disableMobile: true,
                    altInput: false,
                    onClose: function (selectedDates, dateStr, instance) {
                        let stimulateaction = $(el).data('datepickerStimulateaction')
                        if (!stimulateaction) return;
                        controller.stimulate(stimulateaction, instance.element)
                    }
                }
                if (el.dataset.datepickerDisableFeatureDates === '1') {
                    options.maxDate = parseInt(el.dataset.datepickerMaxDate || Date.now());
                }
                flatpickr($(el), options)
            } else {
                let plugins = []
                if (this.warningatorValue) {
                    plugins.push(new warningatorPlugin({
                        warningYear: 1900,
                        text: '<i class="bi bi-exclamation-triangle-fill text-danger" \> Choose the year first',
                    }))
                }
                let options = {
                    plugins: plugins,
                    allowInput: true,
                    enableTime: $(el).data("datepickerDateTime") == '1',
                    altFormat: $(el).data("datepickerDateTime") == '1' ? 'm/d/Y h:i K' : 'm/d/Y',
                    altInput: true,
                    disableMobile: true,
                    parseDate: function (datestr, _format) {
                        dayjs.extend(customParseFormat)
                        let date = dayjs(datestr, ['MMDDYYYY', 'MMDDYY', 'MM-DD-YYYY', 'MM/DD/YYYY', 'YYYY-MM-DD'], true)
                        if (!date.isValid()) {
                            date = dayjs(datestr)
                        }
                        return date.toDate()
                    },
                    onYearChange: function (selectedDates, dateStr, instance) {
                        let selectedDate = selectedDates[0] || new Date()
                        selectedDate.setFullYear(instance.currentYear)
                        selectedDate.setMonth(instance.currentMonth)
                        instance.jumpToDate(selectedDate)
                        instance.setDate(selectedDate)
                    },
                    onMonthChange: function (selectedDates, dateStr, instance) {
                        let selectedDate = selectedDates[0] || new Date()
                        selectedDate.setMonth(instance.currentMonth)
                        instance.jumpToDate(selectedDate)
                        instance.setDate(selectedDate)
                    },
                    onClose: function (selectedDates, dateStr, instance) {
                        if (typeof instance.altInput.value === 'string' && instance.altInput.value.length > 0) {
                            let date = dayjs(instance.altInput.value, ['MMDDYYYY', 'MMDDYY', 'MM-DD-YYYY', 'MM/DD/YYYY', 'YYYY-MM-DD'], true)
                            instance.setDate(date.toDate())
                        } else {
                            instance.clear()
                        }
                        let stimulateaction = $(el).data('datepickerStimulateaction')
                        if (!stimulateaction) return;
                        controller.stimulate(stimulateaction, instance.element)
                    },
                }
                if (el.dataset.datepickerDisableFeatureDates === '1') {
                    options.maxDate = parseInt(el.dataset.datepickerMaxDate || Date.now());
                }
                flatpickr($(el), options)
            }
        });
    }
}
