import {Controller} from 'stimulus'

export default class extends Controller {
    static targets = ["input", "form", "button"]
    static values = {text: String}

    connect() {
        this.disable()
    }

    check() {
        if (this.inputTarget.value === this.textValue) {
            this.enable()
        } else {
            this.disable()
        }
    }

    disable() {
        this.buttonTarget.setAttribute('disabled', true)
    }

    enable() {
        this.buttonTarget.removeAttribute('disabled')
    }
}
