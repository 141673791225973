import {Controller} from 'stimulus'
import StimulusReflex from 'stimulus_reflex';

export default class extends Controller {
    static values = {
        refresh: Number,
        reflex: String
    }

    connect() {
        let controller = this
        this.element[this.identifier] = this
        StimulusReflex.register(controller)
    }

    refreshValueChanged() {
        if(this.refreshValue === 1) {
            this.refreshValue = 0;
            this.stimulate(this.reflexValue);
        }
    }
}
