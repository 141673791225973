import {Controller} from "stimulus"

export default class extends Controller {
    static targets = [ "input", "output" ]

    connect() {

    }

    updateOutput(event) {
        // remove double spaces capitalize and strip each word from input target join with dots and set to output target
        this.outputTarget.value = 'CustomField.' + this.inputTarget.value.replace(/[^a-zA-Z0-9]/g, ' ').trim().replace(/\s+/g, ' ').split(" ").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(".")
    }
}
