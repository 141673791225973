import {Controller} from 'stimulus'

export default class extends Controller {
    static values = {
        reflexAction: String,
        reflexes: Object,
    }

    connect() {
        let controller = this
        this.element[this.identifier] = this
        const cancelMorph = event => {
            event.detail.cancel = !controller.removeReflex(event.detail.stimulusReflex.target, event.detail.reflexId)
            // if (event.detail.cancel) {
            //     console.log('cancelMorph for reflex', event.detail.reflexId)
            // }
        }

        const addReflex = event => {
            if (event.target.dataset.skipReflex) {
                controller.addReflex(event.detail.reflex, event.detail.reflexId)
            }
        }
        const cancelReflex = event => {
            controller.removeReflex(event.detail.reflex, event.detail.reflexId)
        }
        addEventListener("stimulus-reflex:before", addReflex)
        addEventListener("cable-ready:before-morph", cancelMorph)
        addEventListener("stimulus-reflex:error", cancelReflex)
        addEventListener("stimulus-reflex:halted", cancelReflex)
    }

    addReflex(key, value) {
        let a = this.reflexesValue
        a[key] = value
        this.reflexesValue = a
    }

    removeReflex(key, value) {
        if(this.reflexesValue[key] === undefined) {
            return true
        }
        if (this.reflexesValue[key] !== value) {
            return false
        }
        let a = this.reflexesValue
        delete a[key]
        this.reflexesValue = a
        return true
    }
}
