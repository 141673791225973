import { Controller } from "stimulus"

export default class extends Controller {
    static values = {
        refresh: Number
    }

    connect() {
        let tooltipController = this
        this.element[this.identifier] = this
    }

    refreshValueChanged() {
        if(this.refreshValue === 1) {
            this.refreshValue = 0;
            $('div.tooltip').remove();
            let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
            tooltipTriggerList.map(function (tooltipTriggerEl) {
                return new bootstrap.Tooltip(tooltipTriggerEl, { html: true})
            })
        }
    }
}