import { Controller } from "stimulus"
import StimulusReflex from "stimulus_reflex";

export default class extends Controller {
    connect() {
        let controller = this
        this.element[this.identifier] = this
        StimulusReflex.register(controller)

        this.element.querySelectorAll('a').forEach(function(link) {
          link.target = '_blank'
        })
    }
}
