import {
    Controller
} from "stimulus"
import Dropzone from "dropzone"
import 'dropzone/dist/min/dropzone.min.css'
import 'dropzone/dist/min/basic.min.css'
import {
    DirectUpload
} from "@rails/activestorage"

export default class extends Controller {
    static targets = ["input"]

    connect() {
        Dropzone.autoDiscover = false
        this.uploadingFiles = []
        this.inputTarget.disable = true
        this.inputTarget.style.display = "none"
        const controller = this
        const removedfile = function(file) {
            controller.sweetalertHandler(file)
            return this._updateMaxFilesReachedClass();
        }

        const dropzone = new Dropzone(this.element, {
            url: '/',
            maxFiles: '10',
            maxFilesize: '10',
            autoQueue: false,
            addRemoveLinks: true,
            removedfile: removedfile
        })

        dropzone.on("addedfile", file => {
          $('#proposal_send_button').prop('disabled', true)
          controller.uploadingFiles.push(1)
            setTimeout(() => {
                if (file.accepted) {
                    let new_file_name = this.element.getAttribute('data-reflex-dataset') + '---' + file.name
                    let new_file = new File([file], new_file_name, {
                        type: file.type,
                        lastModified: file.lastModified,
                    })

                    const upload = new DirectUpload(new_file, this.url)
                    upload.create((error, blob) => {
                        this.hiddenInput = document.createElement("input")
                        this.hiddenInput.type = "hidden"
                        this.hiddenInput.id = file.upload.uuid
                        this.hiddenInput.name = this.inputTarget.name
                        this.hiddenInput.value = blob.signed_id
                        this.inputTarget.parentNode.insertBefore(this.hiddenInput, this.inputTarget.nextSibling)
                        dropzone.emit("success", file)
                        dropzone.emit("complete", file)
                        $(file.previewElement).on('click', () => {
                          open(URL.createObjectURL(file))
                        })
                        controller.uploadingFiles.shift()
                        if(controller.uploadingFiles.length == 0){
                          $('#proposal_send_button').prop('disabled', false)
                        }
                        // proposal_send_button
                    })
                }
            }, 500)
        })
    }

    get url() {
      return this.inputTarget.getAttribute('data-direct-upload-url')
    }
    sweetalertHandler(file) {
        Swal.fire({
            customClass: {
                confirmButton: 'btn btn-danger ms-2 order-2',
                cancelButton: 'btn btn-outline-primary',
            },
            buttonsStyling: false,
            title: '<i class="bi bi-exclamation-triangle-fill bg-danger-10 d-flex justify-content-center align-items-center rounded-circle me-3"></i> Delete attachment',
            html: `Do you want to delete file ${file.name}?`,
            showCancelButton: true,
            confirmButtonText: 'Delete',
        }).then((result) => {
            if (result.isConfirmed) {
                document.body.classList.remove('swal2-shown', 'swal2-height-auto')
                if (file.previewElement != null && file.previewElement.parentNode != null) {
                    file.previewElement.parentNode.removeChild(file.previewElement);
                    $(`#${file.upload.uuid}`).remove()
                }
            }
        })
    }
}
