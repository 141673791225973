import {Controller} from "stimulus"
import {useClickOutside} from 'stimulus-use'
import StimulusReflex from "stimulus_reflex";

export default class extends Controller {
    static values = {event: String}

    connect() {
        StimulusReflex.register(this)
        useClickOutside(this)
    }

    call(_event) {
        this.stimulate(this.eventValue, this.element)
    }
}