import {Controller} from "stimulus"

export default class extends Controller {
    static values = {
      selector: String,
      scroller: String,
    }

    connect() {
      this.element[this.identifier] = this
    }

    scroll(event) {
      let el = document.querySelector(this.selectorValue)
      if (el === null) return;

      event.preventDefault();
      event.stopPropagation();

      el.scrollIntoView({block: "center", behavior: "smooth"});
    }

    scrollerValueChanged() {
      if (this.scrollerValue === '') return
      window.scrollTo({ top: this.scrollerValue, behavior: 'smooth' })
      this.scrollerValue = ''
    }
}
