import { Controller } from "stimulus"
import StimulusReflex from "stimulus_reflex";

export default class extends Controller {
    connect() {
        let controller = this
        this.element[this.identifier] = this
        StimulusReflex.register(controller)

        this.element.querySelectorAll('a').forEach(function(current_link) {
          const htmlString = current_link.outerHTML
          const tempElement = document.createElement('div');
          tempElement.innerHTML = htmlString;
          const url = tempElement.firstChild.getAttribute('href');
          const decodedUrl = decodeURIComponent(url);
          current_link.href = decodedUrl
        })
    }
}
