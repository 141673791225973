import {Controller} from "stimulus"
import StimulusReflex from "stimulus_reflex";

export default class extends Controller {
    static values = { event: String, elementId: String, checkboxId: String }

    connect() {
      StimulusReflex.register(this)
    }

    submit(event) {
      let form = document.getElementById(this.elementIdValue);
      $("#paymentCheckbox").remove();
      let checkedValue = $(`#${this.checkboxIdValue}`).is(':checked') ? '1' : '0';
      let checkbox = document.createElement("input");
      checkbox.setAttribute('name', 'show_card');
      checkbox.setAttribute('id', 'paymentCheckbox');
      checkbox.setAttribute('type', 'hidden');
      checkbox.setAttribute('value', checkedValue);
      form.appendChild(checkbox);
      this.stimulate(this.eventValue, form)
    }
}
