import { Controller } from "stimulus"
import StimulusReflex from "stimulus_reflex";
import * as debounce from 'lodash.debounce';

export default class extends Controller {
    static values = {
        refresh: Number
    }

    connect() {
        this.element[this.identifier] = this
        StimulusReflex.register(this)
    }

    init_columns_width() {
        const callback = debounce(
            () => outputsize(),
            250,
        );

        $('thead tr th').each(function() {
            var element = ($( this )[0])
            const ro = new ResizeObserver(callback);
            ro.observe(element);
        });

        $('thead tr th').each(function() {
            var element = ($( this )[0])
            new ResizeObserver(changeTableSize).observe(element)
        });

        $(function() {
            var thHeight = $("table#resizable_table th:first").height();
            $("table#resizable_table th").resizable({
                handles: "e",
                minHeight: thHeight,
                maxHeight: thHeight,
                minWidth: 40,
                resize: function (event, ui) {
                    var sizerID = "#" + $(event.target).attr("id") + "-sizer";
                    $(sizerID).width(ui.size.width);
                }
            });
        });
    }

    refreshValueChanged() {
        if(this.refreshValue === 1) {
            this.init_columns_width()
            this.refreshValue = 0
        }
    }
}

function changeTableSize() {
    $('table').css('min-width', (parseInt($('.sticky-top').css('width')) + 40) + 'px')
}

function outputsize() {
    var columns_width = new Object();
    $('thead tr th').each(function() {
        var element = ($( this ))
        if($.trim(element.find('a').data( "column-name" ))){
            columns_width[$.trim(element.find('a').data( "column-name" ))] = element.css( "width" )
        }
    })

    if(!jQuery.isEmptyObject(columns_width)) {
        $.post( "cases/column_widths", { columns_width: JSON.stringify(columns_width) })
    }

}
