import {Controller} from "stimulus"
import StimulusReflex from "stimulus_reflex";
import $ from "jquery";

window.bootstrap = require('bootstrap');
require('../packs/bootstrap-select-temp')
// require('bootstrap-select')

export default class extends Controller {
    static targets = ['select'];
    static values = {
        refresh: Number
    }

    connect() {
        this.element[this.identifier] = this
        StimulusReflex.register(this)
    }

    destroy_select(el) {
        if (el['selectpicker'] === undefined) return;

        $(el).removeData('selectpicker')
        $(el).off()
        $(window).off('.bs.select.' + el['selectpicker']['selectId']);
    }

    init_selects() {
        let controller = this;
        this.selectTargets.forEach((el, index) => {
            controller.destroy_select(el)
            let selectPicker = $(el).selectpicker()
            el['selectpicker'] = {}
            el['selectpicker']['selectId'] = $(el).data('selectpicker').selectId
            el['selectpicker']['values'] = controller.getSelectValues(el) || [];
            el['selectpicker']['new_values'] = el['selectpicker']['values'];
            $(el).on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
                e.target.setAttribute('data-search-value', '')
                e.target['selectpicker']['new_values'] = controller.getSelectValues(e.target)
                $(this).next().next().children(".bs-searchbox").children("input[type='search']").blur()
            })

            $(el).on('show.bs.select', function (e, clickedIndex, isSelected, previousValue) {
                let el2 = $('.bs-searchbox').find('input[type="search"]')
                let element = el2.get(0)
                if(element != undefined) {
                    element.addEventListener("keypress", function (event) {
                        if (event.key !== "Enter") return

                        event.preventDefault();
                        $('.custom-select-value').trigger("click");
                        $(selectPicker).selectpicker('toggle');
                    });
                }
            })
            $(el).on('hidden.bs.select', function (e) {
                let select = e.target
                let selected_option = select.options[select.selectedIndex]
                if (selected_option !== undefined) {
                    let optionDataset = selected_option.dataset
                    Object.keys(optionDataset).forEach((key) => {
                        select.dataset[key] = optionDataset[key]
                    })
                }
                let sp = select['selectpicker']
                if (controller.equalsIgnoreOrder(sp['values'], sp['new_values'])) return;

                sp['values'] = sp['new_values']
                let stimulateaction = select.getAttribute('data-select-stimulateaction')
                if (stimulateaction === null) return;
                controller.stimulate(stimulateaction, select)
            });
            $(el).on('hide.bs.select', function (e, clickedIndex, isSelected, previousValue) {
                if ($('.custom-select-value').length > 0) {
                    $('.custom-select-value').trigger("click")
                }
                else if (e.target.getAttribute('data-search-value') !== null && e.target.getAttribute('data-search-value').length > 0) {
                    let searchValue = e.target.getAttribute('data-search-value')
                    if(searchValue.length < 1) return;
                    $(e.target).find(".option-custom-select-value").remove()
                    $(e.target).append('<option class="option-custom-select-value" value="' + searchValue + '">' + searchValue + '</option>')
                    if(e.target.dataset.skipUpdateOnNonMatch != 'true') {
                      $(e.target).val(searchValue).change()
                      $(e.target).selectpicker('refresh')
                    }
                }
                // Remove Disable class from btns after selectpicker list is closed
                document.querySelectorAll('[data-controller-select-disable="true"]').forEach((el, index) => {
                    $(el).removeClass('disabled')
                })
            });

            // Disable btns while selectpicker list is open
            $(el).on('show.bs.select', function (e, clickedIndex, isSelected, previousValue) {
                document.querySelectorAll('[data-controller-select-disable="true"]').forEach((el, index) => {
                    $(el).addClass('disabled')
                })
            })
        })
    }

    disconnect() {
        this.selectTargets.forEach((el, index) => {
            $(el).off()
            $(el).selectpicker('destroy')
        })
    }

    refreshValueChanged() {
        if (this.refreshValue === 1) {
            $('div.dropdown-menu.show').removeClass('show')
            this.init_selects()
            this.refreshValue = 0
        }
    }

    getSelectValues(select) {
        var value = [],
            options = select.selectedOptions,
            opt;

        for (var i = 0, len = options.length; i < len; i++) {
            opt = options[i];

            if (!(opt.disabled || opt.parentNode.tagName === 'OPTGROUP' && opt.parentNode.disabled)) {
                value.push(opt.value);
            }
        }

        return value;
    }

    equalsIgnoreOrder(a, b) {
        if (a.length !== b.length) return false;
        const uniqueValues = new Set([...a, ...b]);
        for (const v of uniqueValues) {
            const aCount = a.filter(e => e === v).length;
            const bCount = b.filter(e => e === v).length;
            if (aCount !== bCount) return false;
        }
        return true;
    }

    reloadCurrentPage(event) {
        this.stimulate(event.target.dataset.event).then(_ => window.location.reload());
    }

    processEvent(event) {
        this.stimulate(event.target.dataset.event);
    }
}
