import {Controller} from "stimulus"
import StimulusReflex from 'stimulus_reflex'

export default class extends Controller {
    static values = {
        key: String, authenticityToken: String, linkAccount: Number
    }

    connect() {
        this.element[this.identifier] = this
        StimulusReflex.register(this)
        this.stripe = Stripe(this.keyValue)
    }

    submitEmptyBankAccount(event) {
        event.preventDefault()
        let controller = this
        // find form with id new_payrix_bank_account
        let form = document.getElementById('new_payrix_bank_account')
        this.stimulate('Backend::Payrix#submit_bank_account', form, true)
    }

    linkAccountValueChanged(event) {
        if (this.linkAccountValue !== 1) return
        this.linkAccountValue = 0
        this.linkAccount()
    }

    linkAccount() {
        let controller = this

        // rails post request with authenticity_token
        fetch("/setup/stripe/session?authenticity_token=" + controller.authenticityTokenValue, {
            method: 'POST',
        }).then(function (response) {
            return response.json();
        }).then(function (verificationSession) {
            controller.stripe.collectBankAccountToken({
                clientSecret: verificationSession.client_secret
            }).then(function (result) {
                if (result.error) {
                    // Inform the customer that there was an error.
                } else if (result.token) {
                    fetch("/setup/stripe/link_account?authenticity_token=" + controller.authenticityTokenValue + '&btok=' + result.token.id, {
                        method: 'POST',
                    }).then(function (result) {
                        document.getElementById('redirector').dataset.redirectorLocationValue = '/setup/success?gateway=stripe'
                    })
                }
            });
        })
    }
}
