import {Controller} from 'stimulus'
import StimulusReflex from 'stimulus_reflex'
import Sortable from "sortablejs";

export default class extends Controller {
    static values = {
        reflexAction: String,
        formId: String
    }

    connect() {
        let controller = this
        this.element[this.identifier] = this
        StimulusReflex.register(controller)
        new Sortable(sortable, {
            animation: 150,
            ghostClass: 'sortable-drag-color',
            handle: '.handle',
            onSort: function (event) {
                if (controller.formIdValue && controller.reflexActionValue) {
                    controller.stimulate(controller.reflexActionValue, document.getElementById(controller.formIdValue))
                }
            }
        });
    }
}
