import {Controller} from "stimulus"
import {Modal} from 'bootstrap'
import $ from "jquery";

export default class extends Controller {
    static values = {
        close: Boolean,
        keep: Boolean,
        keepOpen: Boolean,
    }

    connect() {
        let controller = this
        $('#modal-window').on('hide.bs.modal', function () {
            controller.closeValue = true
        });
    }

    disconnect() {
        this.closeValue = true
        $('#modal-window').off('hide.bs.modal')
    }

    close(event) {
        event.preventDefault()
        event.stopPropagation()
        if (this.keepOpenValue) return

        this.closeValue = true
    }

    closeValueChanged() {
        if (this.closeValue === true || !this.hasCloseValue) {
            this.hide_and_clear()
            $("button[data-disable-on-reflex]").removeAttr("disabled")
        } else if (this.hasCloseValue) {
            let myModal = new Modal(this.element, {})
            myModal.show()
        }
    }

    hide_and_clear() {
        $(this.element).modal('hide')
        document.body.classList.remove('modal-open')
        document.body.style.removeProperty('overflow')
        document.body.style.removeProperty('padding-right')
        if (!this.keepValue) {
            $(this.element).html('')
        }
    }
}
