import {Controller} from 'stimulus'
import StimulusReflex from 'stimulus_reflex';

export default class extends Controller {
    connect() {
        let controller = this
        this.element[this.identifier] = this
        StimulusReflex.register(controller)
        new Sortable(
            this.element,
            {
                swapThreshold: 1,
                ghostClass: 'drag',
                animation: 150,
                filter: '.not-sortable',
                handle: ".drag-handler",
                group: {
                    name: 'questions_groups',
                },
                onChoose: function (event) {
                    setTimeout(() => {
                        document.querySelectorAll('.questions_group_body').forEach(function (el) {
                            el.classList.toggle('d-none');
                        });
                    }, 500);
                },
                onUnchoose: function (event) {
                    document.querySelectorAll('.questions_group_body').forEach(function (el) {
                        el.classList.toggle('d-none')
                    });
                },
                onSort: function (event) {
                    event.item.dataset.sortOrder = event.newIndex
                    controller.stimulate(
                        'Backend::Vitals::QuestionsGroupReflex#sort',
                        event.item,
                    )
                },
            }
        )
    }
}
