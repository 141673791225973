import {Controller} from "stimulus"
import StimulusReflex from "stimulus_reflex";

export default class extends Controller {
    static values = {
        url: String,
        event: String,
        refresh: Number
    }
    static targets = ['input'];

    connect() {
        let controller                = this
        this.element[this.identifier] = this
        StimulusReflex.register(controller)
    }

    init_search() {
        var options = {
            url: function (phrase) {
                return "/cases/global_search.json?filter_by%5Bsearch%5D=" + phrase;
            },

            categories: [{
                listLocation: "decedent",
                maxNumberOfElements: 5,
                header: "Decedent name"
            }
                , {
                    listLocation: "informant",
                    maxNumberOfElements: 5,
                    header: "Informant name"
                }
                ,{
                    listLocation: "case-id",
                    maxNumberOfElements: 5,
                    header: "Case ID"
                }, {
                    listLocation: "phone",
                    maxNumberOfElements: 5,
                    header: "Phone"
                }],

            getValue: function(element) {
                return element.text;
            },

            template: {
                type: "links",
                fields: {
                    link: "website-link"
                }
            },

            list: {
                maxNumberOfElements: 20,
                sort: {
                    enabled: true
                },
                onLoadEvent: function() {
                    $("#eac-container-global_search_query ul").append('<li class="border-top p-0"><button class="btn btn-link px-2 w-100 rounded-0 text-start text-decoration-none text-primary fs-6-125">See all search results <i class="bi bi-box-arrow-up-right d-none d-md-inline-block ms-2"></i></button></li>');
                }
            },

            theme: "square"
        };
        $("#global_search_query").easyAutocomplete(options);
    }

    refreshValueChanged() {
        if(this.refreshValue === 1) {
            this.init_search()
            this.refreshValue = 0
        }
    }
}
