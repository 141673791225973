import {Controller} from "stimulus"
import StimulusReflex from 'stimulus_reflex'
import Coloris from "@melloware/coloris";

export default class extends Controller {
    static targets = ["input"]
    static values = { swatches: Array, refresh: Number, reflexAction: String }


    connect() {
      this.element[this.identifier] = this
      StimulusReflex.register(this)
      if(this.element.id !== ''){
        this.initialize_coloris()
      }
    }

    refreshValueChanged() {
      if(this.refreshValue === 1) {
        this.initialize_coloris()
        this.refreshValue = 0
      }
    }

    initialize_coloris() {
      let controller = this
      Coloris.init();
      let data = {
        theme: 'pill',
        alpha: true,
        themeMode: 'dark',
        closeButton: true,
        clearButton: true,
        swatches: this.swatchesValue,
      }
      if(window.changeColorisFunction == undefined) {
        window.changeColorisFunction = function(e) {
          let element = document.getElementById('status-form')
          controller.stimulate(controller.reflexActionValue, element)
        }
      }
      if(controller.reflexActionValue !== '') {
        this.element.removeEventListener('debounced:input', window.changeColorisFunction);
        this.element.addEventListener('debounced:input', window.changeColorisFunction);

      }
      if(this.element.dataset.preview) {
        let onChangeHandler = (color, input) => {
          $(`#${this.element.dataset.preview}`).css('background-color', color)
        }

        data.onChange = onChangeHandler
      }
      Coloris.setInstance('#' + this.element.id, data);
    }
}
