import {Controller} from "stimulus"
import {useClickOutside} from 'stimulus-use'

export default class extends Controller {
    static targets = ["mask", "input"]

    connect() {
        useClickOutside(this, {element: this.inputTarget})
    }

    clickOutside(event) {
        if (event.target != this.maskTarget && this.maskTarget.classList.contains('visually-hidden')) {
            this.showMask(event)
        }
    }

    showInput(_event) {
        this.maskTarget.classList.add('visually-hidden')
        this.inputTarget.classList.remove('visually-hidden')
        this.inputTarget.focus()
    }

    showMask(event) {
        event.preventDefault()
        event.stopPropagation()
        if (this.inputTarget.value !== '' && this.inputTarget.value !== undefined && this.inputTarget.value !== null) {
            this.maskTarget.textContent = this.inputTarget.value
        }
        this.maskTarget.classList.remove('visually-hidden')
        this.inputTarget.blur()
        this.inputTarget.classList.add('visually-hidden')
    }
}
