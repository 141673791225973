import {Controller} from "stimulus"
import $ from "jquery";

export default class extends Controller {
    static targets = ["source"]
    static values = {
        refresh: Number,
        placement: String,
        containerId: String,
    }

    connect() {
        this.element[this.identifier] = this
    }

    refreshValueChanged() {
        if (this.refreshValue === 1) {
            this.initialize_clipboard()
            this.refreshValue = 0
        }
    }

    initialize_clipboard() {
        let options = {}
        if (this.containerIdValue !== '') {
            options = {container: document.getElementById(this.containerIdValue)}
        }
        new ClipboardJS(this.element, options);
        this.showTooltip()
    }

    showTooltip() {
        let placement = this.placementValue === '' ? 'left' : this.placementValue
        $(this.element).tooltip({
            animated: 'fade',
            placement: placement,
            trigger: 'click'
        }).attr('data-bs-original-title', 'copied');

        $(this.element).click(function () {
            var that = $(this)
            setTimeout(function () {
                that.tooltip('hide');
            }, 1000);
        })
    }
}
