import {Controller} from "stimulus"

export default class extends Controller {
    connect() {
        let controller = this

    }

    checkIntercomTask(event) {
        event.preventDefault()
        event.stopPropagation()
        // check if element with id intercom-positioner-tree exists and return if not
        if (!document.getElementById('intercom-positioner-tree')) {
            window.location.href = event.target.getAttribute('href')
            return
        }
        // update href attribute of the event.target element add intercom=1 to the query string
        let href = event.target.getAttribute('href')
        let new_href = href + (href.includes('?') ? '&' : '?') + 'intercom=1'
        window.location.href = new_href
    }
}
