import {Controller} from 'stimulus'
import StimulusReflex from 'stimulus_reflex'
import Sortable from "sortablejs";

export default class extends Controller {
  static values = {
    reflexAction: String,
    taskGroup: String,
    taskId: String
  }

  connect() {
      let controller = this
      this.element[this.identifier] = this
      StimulusReflex.register(controller)
      new Sortable(controller.element, {
          ghostClass: 'drag',
          animation: 150,
          handle: ".custom-handle",
          onSort: function (event) {
            const form = document.getElementById('status-form-order')
            document.getElementById('task-status-id-form-order').value = event.item.dataset.statusId
            document.getElementById('task-manager-status-group-id-form-order').value = controller.element.dataset.taskGroupId
            document.getElementById('order-form-order').value = event.newIndex
            controller.stimulate(controller.element.dataset.reflexAction, form)
          }
      });
  }
}
