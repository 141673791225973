import {Controller} from "stimulus"
import StimulusReflex from "stimulus_reflex";

export default class extends Controller {
  static values = {
    refresh: Number
  }

    connect() {
      console.log($(this.element))
      StimulusReflex.register(this);
      const element = $(this.element).find('.textarea-responsive')[0]
      if(element == undefined) return
      element.style.height = 'auto';
      element.style.height = element.scrollHeight + 'px';
      element.addEventListener('input', autoResize, false);
        function autoResize() {
          this.style.height = 'auto';
          this.style.height = this.scrollHeight + 'px';
        }
    }

    refreshValueChanged() {
      if (this.refreshValue === 1) {
        const element = $(this.element).find('.textarea-responsive')[0]
        if(element == undefined) return
        element.style.height = 'auto';
        element.style.height = element.scrollHeight + 'px';
        element.addEventListener('input', autoResize, false);
          function autoResize() {
            this.style.height = 'auto';
            this.style.height = this.scrollHeight + 'px';
          }
          this.refreshValue = 0
      }
  }
}
