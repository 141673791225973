import {Controller} from 'stimulus'
import StimulusReflex from 'stimulus_reflex'

export default class extends Controller {

  static values = {
    refresh: Number,
    note: String
  }

  showError(note) {
    Swal.fire({
      title: '<i class="bi bi-check bg-primary-10 d-flex justify-content-center align-items-center rounded-circle me-3 text-primary"></i> Export file is ready',
      showConfirmButton: false,
      showCancelButton: true,
      customClass: {
        cancelButton: 'btn btn-outline-primary fs-6-125 m-0 bg-transparent border border-color-primary text-primary py-2 px-3',
      },
      html: `<div class="text-center"> <div class="position-relative d-inline-block bg-primary-10 p-3 rounded-circle mx-auto mt-2 mb-1"><i class="icon-download-custom position-absolute top-50 left-50 translate-middle"></i></div> <p class="m-0 fs-6-125 fw-medium lh-1">${note}</p></div>`
     })
  }

  connect() {
    let controller = this
    this.element[this.identifier] = this
    StimulusReflex.register(controller)
  }

  refreshValueChanged() {
    if (this.refreshValue === 1) {
      this.showError(this.noteValue)
      this.refreshValue = 0
    }
  }
}
