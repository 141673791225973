// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import 'bootstrap'
import 'bootstrap-icons/font/bootstrap-icons.css'
import "../stylesheets/application"
import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "controllers"
import $ from 'jquery';
import debounced from 'debounced'
import "@melloware/coloris/dist/coloris.css";
import Coloris from "@melloware/coloris";

Coloris.init();
Coloris({el: '.coloris'})
debounced.initialize()

Rails.start()
ActiveStorage.start()
global.ScrollSpy = ScrollSpy

global.$ = $;
global.jQuery = $;
import Swal from 'sweetalert2'

global.Swal = Swal

import flatpickr from "flatpickr";

global.flatpickr = flatpickr
import 'flatpickr/dist/themes/airbnb.css'
import {ScrollSpy} from "bootstrap";

// Selectpicker
window.bootstrap = require('bootstrap');
// require('bootstrap-select/dist/js/bootstrap-select')
require('../packs/bootstrap-select-temp')
$.fn.selectpicker.Constructor.BootstrapVersion = '5';

require('sortablejs')
import Sortable from "sortablejs";

global.Sortable = Sortable

import ClipboardJS from "clipboard/dist/clipboard.min";

global.ClipboardJS = ClipboardJS
import 'easy-autocomplete/dist/jquery.easy-autocomplete.min'

import "../misc/trix_editor";
import "jquery-ui/ui/widgets/resizable"
import "@rails/actiontext";

window.addEventListener('load', function () {
    let navigationEntries = performance.getEntriesByType('navigation');

    if (navigationEntries.length > 0 && navigationEntries[0].type === 'back_forward') {
        window.location.reload();
    }
});

document.addEventListener('cable-ready:after-morph', event => {
    const focusElement = document.querySelector('[autofocus]')
    if (focusElement) {
        focusElement.focus()

        // shenanigans to ensure that the cursor is placed at the end of the existing value
        const value = focusElement.value
        focusElement.value = ''
        focusElement.value = value
    }
})

document.addEventListener('stimulus-reflex:connected', () => {
    document.body.setAttribute('data-sr-connected', '1');
})

document.addEventListener('cable-ready:after-morph', event => {
    let value = parseInt(document.body.getAttribute('data-sr-connected'))
    document.body.setAttribute('data-sr-connected', value + 1);
})

document.addEventListener('cable-ready:after-inner-html', event => {
    let value = parseInt(document.body.getAttribute('data-sr-connected'))
    document.body.setAttribute('data-sr-connected', value + 1);
})

document.addEventListener('autoNumeric:rawValueModified', (event) => {
    let id = event.target.id.replace('numeric_', '')
    let hidden_input = document.getElementById(id)
    hidden_input.value = event.detail.newRawValue
})

$(document).on('keyup keypress', 'form input[type="text"]', function (e) {
    if (e.target.classList.contains('allow-enter')) {
        return true
    }

    if (e.keyCode == 13) {
        e.preventDefault();
        return false;
    }
})
