import {Controller} from "stimulus"

export default class extends Controller {
    static values = {
        refresh: Number
    }

    connect() {
        this.element[this.identifier] = this
        //this.collapseTabs()
        new ResizeObserver(this.collapseTabs).observe(this.element)
    }

    collapseTabs(event) {
        let target = event[0].target
        let container_width = target.offsetWidth
        let links = []
        let links_width = 0
        target.querySelectorAll("a").forEach((el, index) => {
            let width = parseFloat(el.getAttribute('data-width')) || $(el).parent().outerWidth(true);
            links_width += width
            let position = parseInt(el.getAttribute('data-position')) || index
            if (width === null) {
                width = $(el).parent().outerWidth(true)
                el.setAttribute('data-width', width)
            } else {
                width = parseFloat(width)
            }
            let hash = {
                href: el.href,
                text: el.innerText,
                position: position,
                active: el.classList.contains('active'),
                width: width,
                visible: !el.classList.contains('p-2')
            }
            if (hash.active) {
                links.unshift(hash)
            } else {
                links.push(hash)
            }
        })

        if (links_width <= container_width) {
            links.sort(function (a, b) {
                if (a.position < b.position) return -1;
                if (a.position > b.position) return 1;
                return 0;
            });
        }

        let excess_li = target.querySelector('li.position-relative')

        let excess_li_width;
        if (excess_li.classList.contains('d-none')) {
            excess_li.classList.toggle('d-none')
            excess_li_width = $(excess_li).outerWidth(true)
            excess_li.classList.toggle('d-none')
        } else {
            excess_li_width = $(excess_li).outerWidth(true)
        }

        let width = 0;

        let has_hidden = false
        links.forEach(function (link, index) {
            width += link.width;
            if (width > container_width) {
                link.visible = false
                has_hidden = true
                if ((width - link.width + excess_li_width > container_width) && index > 0) {
                    links[index - 1].visible = false
                }
            } else {
                link.visible = true
            }
        })

        target.querySelectorAll("li").forEach((el, index) => {
            if (!el.classList.contains('excess_tab')) el.remove()
        })


        let dropdown_ul = excess_li.querySelector('ul.dropdown-menu')
        links.forEach(function (link) {
            let li = document.createElement('li');
            let a = document.createElement('a');
            a.href = link.href
            a.innerText = link.text
            a.setAttribute('data-width', link.width)
            a.setAttribute('data-position', link.position)
            if (link.active && link.visible) {
                a.className = 'position-relative d-block py-2 text-decoration-none fs-6-125 active'
                li.className = 'nav-item px-3 mx-0'
                li.appendChild(a)
                target.insertBefore(li, excess_li);
            } else if (link.visible) {
                a.className = 'position-relative d-block py-2 text-decoration-none fs-6-125'
                li.className = 'nav-item px-3 mx-0'
                li.appendChild(a)
                target.insertBefore(li, excess_li);
            } else {
                a.className = 'position-relative text-decoration-none p-2 d-block py-sm-2 px-sm-0 fs-6-125'
                if(link.active) a.classList.toggle('active')
                li.className = 'nav-item mx-sm-3 mx-md-4'
                li.appendChild(a)
                dropdown_ul.appendChild(li)
            }
        })

        if (has_hidden) {
            excess_li.classList.remove('d-none')
        } else {
            excess_li.classList.add('d-none')
        }
    }
}