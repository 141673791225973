import {Controller} from "stimulus"

export default class extends Controller {
    static targets = ["raw"]
    static values = {
        raw: Object,
        refresh: Number,
    }

    connect() {
    }

    refreshValueChanged() {
        if (this.refreshValue !== 1) return;

        this.refreshValue = 0
        let controller = this
        let raw = JSON.parse(this.rawTarget.textContent)
        raw.forEach(function (hash) {
            var row = document.createElement('tr');

            // Ячейка для значения name
            var nameCell = document.createElement('td');
            nameCell.className = 'ps-3 pe-0';
            var nameText = document.createTextNode(hash.name);
            nameCell.appendChild(nameText);
            row.appendChild(nameCell);

            // Ячейка для значения value и кнопки
            var valueCell = document.createElement('td');
            valueCell.className = 'ps-3 pe-0';
            var valueText = document.createTextNode(hash.value);
            valueCell.appendChild(valueText);

            var button = document.createElement('button');
            button.name = 'button';
            button.type = 'button';
            button.className = 'btn';
            button.title = 'copied';
            button.setAttribute('data-controller', 'clipboard');
            button.setAttribute('data-event-name', 'Case details preview item copied');
            button.setAttribute('data-toggle', 'tooltip');
            button.setAttribute('data-clipboard-placement-value', 'top');
            button.setAttribute('data-clipboard-refresh-value', '0');
            button.setAttribute('data-clipboard-container-id-value', 'panda-doc-fields');
            button.setAttribute('data-clipboard-action', 'copy');
            button.setAttribute('data-clipboard-text', hash.value);
            button.setAttribute('data-bs-original-title', 'copied');

            var buttonIcon = document.createElement('i');
            buttonIcon.className = 'bi bi-files';

            button.appendChild(buttonIcon);
            valueCell.appendChild(button);
            row.appendChild(valueCell);

            // Ячейка для значения type
            var typeCell = document.createElement('td');
            typeCell.className = 'ps-3 pe-2';
            var typeText = document.createTextNode(hash.type);
            typeCell.appendChild(typeText);
            row.appendChild(typeCell);

            controller.element.appendChild(row);
        });

        setTimeout(function () {
            let buttons = document.querySelectorAll('button[data-clipboard-refresh-value="0"]');
            buttons.forEach(function (button) {
                button.setAttribute('data-clipboard-refresh-value', '1');
            });
        }, 100);
    }
}
