import {Controller} from "stimulus"
import StimulusReflex from "stimulus_reflex";

export default class extends Controller {
    static values = { event: String, elementId: String }

    connect() {
      StimulusReflex.register(this)
    }

    submit(event) {
      if(this.elementIdValue){
        this.stimulate(this.eventValue, document.getElementById(this.elementIdValue))
      }
      else {
        this.stimulate(this.eventValue, event.target.form)
      }
    }
}
