import {Controller} from 'stimulus'
import StimulusReflex from 'stimulus_reflex'
import $ from "jquery";
import 'bootstrap-toggle'

export default class extends Controller {
    static targets = ['select']
    static values  = {
        refresh: Number
    }

    connect() {
        this.element[this.identifier] = this
        StimulusReflex.register(this)
    }

    refreshValueChanged() {
        if (this.refreshValue === 1) {
            this.initialize_toggle()
            this.refreshValue = 0
        }
    }

    initialize_toggle() {
      this.selectTargets.forEach((el, index) => {
        $(el).bootstrapToggle('destroy')
        $(el).off('change');
        $(el).bootstrapToggle();
        const submitForm = el.dataset.autoSubmitForm;
        $(el).on('change', () => {
          if(!!submitForm) {
            const form = $(el).closest('form')
            this.stimulate(submitForm, form[0])
          }
        })
      })
    }
}
