import { Controller } from "stimulus"
import StimulusReflex from "stimulus_reflex";

export default class extends Controller {
    connect() {
        let controller = this
        this.element[this.identifier] = this
        StimulusReflex.register(controller)
        if(!this.element.dataset.skipUpdate) {
          this.element.addEventListener("trix-change", event => {
              controller.stimulate('Backend::CaseNotificationReflex#proposal_notification_submit', controller.element)
          })
        }

        this.element.querySelectorAll('a').forEach(function(link) {
          link.pathname = link.pathname //Dont ask me what is it :(
        })
    }
}
