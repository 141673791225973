import {Controller} from "stimulus"
import '@claviska/jquery-minicolors'

export default class extends Controller {
    static targets = ["input"]
    connect() {
        this.element[this.identifier] = this
        $(this.inputTarget).minicolors({});
    }
}
