import {Controller} from 'stimulus'
import StimulusReflex from 'stimulus_reflex';

export default class extends Controller {
    static values = {
        id: String,
    }

    connect() {
        let previewController         = this
        this.element[this.identifier] = this
        StimulusReflex.register(previewController)
    }

    modal_window(event) {
        if (this.element.getAttribute('data-disable-on-reflex') == 'true') {
            this.element.disabled = true;
        }
        let previewController = this
        previewController.stimulate(previewController.data.get("stimulateaction"), $('#' + previewController.idValue).val())
    }
}
