import {Controller} from 'stimulus'
import StimulusReflex from 'stimulus_reflex';

export default class extends Controller {
  static values = {
    refresh: Number,
    url: String
  }

  connect() {
    let previewController         = this
    this.element[this.identifier] = this
    StimulusReflex.register(previewController)
  }

  refreshValueChanged() {
    if(this.refreshValue === 1) {
      this.refreshValue = 0;
      window.location.href = window.location.origin + this.urlValue
    }
  }
}
