import {Controller} from 'stimulus'
import StimulusReflex from 'stimulus_reflex'
import Sortable from "sortablejs";

export default class extends Controller {
  static values = {
    reflexAction: String,
    taskGroup: String,
    taskId: String,
    movingTaskId: Array,
    rootMovingTaskId: Array,
    refresh: Number
  }

  refreshValueChanged() {
    if(this.refreshValue == 1) {
      this.initSorting();
      this.refreshValue = 0;
    }
  }

  connect() {
    let controller = this
    this.element[this.identifier] = this
    StimulusReflex.register(controller)
    this.initSorting();
  }

  initSorting() {
    if(window.sortableElements != undefined) {
      window.sortableElements.forEach(function (element) {
        element.destroy();
      });
    }
      window.sortableElements = []
      let controller = this

      let onEndHandler = function (event) {
        let type = ''
        if($(event.to).hasClass('nested-tasks')) {
          type = 'subtask'
          // $(event.item).find('.nested-tasks').remove()
          event.item.dataset.rootTaskId = event.to.dataset.parenttaskid

        }
        else {
          type = 'task'
        }

        event.item.dataset.sortOrder = event.newIndex
        event.item.dataset.type = type
        controller.stimulate('Backend::Admin::TaskManagers::TaskReflex#sort_task', event.item)
      }
      let handleClass = '.sortable-root-btn'
      let mobile = false
      if(window.innerWidth <= 1200) {
        // handleClass = '.sortable-root-btn-mobile'
        mobile = true
      }
      let rootConainer = document.getElementById('root-tasks')
      let options = {
        group: 'shared',
        animation: 0,
        swapThreshold: 0.65,
        ghostClass: 'sortable-drag-color',
        handle: handleClass,
        onEnd: onEndHandler,
        fallbackOnBody: false,
        fallbackClass: "sortable-fallback",
        onMove: function (evt, originalEvent) {
          if (evt.from === evt.to) return true;
          let elementLength = $(evt.dragged.querySelector('.nested-tasks')).children().length;
          if (evt.from !== evt.to && elementLength != 0) {
            return false;
          }
          return true;
        },
        // onClone: function (evt) {
        //   $(evt.item).addClass("sortable-clone");
        // }
      }
      if(mobile) {
        options['fallbackTolerance'] = 3
        options['delay'] = 300
        options['forceFallback'] = true
        options['fallbackOnBody'] = true
      }
      console.log(options)
      let sortable =  new Sortable(rootConainer, options);
      window.sortableElements.push(sortable);

        document.querySelectorAll('.nested-tasks').forEach(function (nestedList) {
          let nestedOptions = {
            group: 'shared',
            animation: 0,
            fallbackOnBody: true,
            ghostClass: 'sortable-drag-color',
            swapThreshold: 0.65,
            handle: handleClass,
            onEnd: onEndHandler
          }
          if(mobile) {
            nestedOptions['fallbackTolerance'] = 3
            nestedOptions['delay'] = 300
            nestedOptions['forceFallback'] = true
            nestedOptions['fallbackOnBody'] = true
          }
          let sortable = new Sortable(nestedList, nestedOptions);
          window.sortableElements.push(sortable);
        });
  }
}
