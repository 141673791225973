import {Controller} from 'stimulus'

export default class extends Controller {
    connect() {
        this.element[this.identifier] = this
        new Sortable(
            this.element,
            {
                swapThreshold: 1,
                ghostClass: 'drag',
                animation: 150,
                filter: '.not-sortable',
                group: {
                    name: 'questions_groups',
                    pull: 'clone',
                    put: false,
                },
                sort: false,
                onChoose: function (event) {
                    setTimeout(() => {
                        document.querySelectorAll('.questions_group_body').forEach(function (el) {
                            el.classList.toggle('d-none');
                        });
                    }, 500);
                },
                onUnchoose: function (event) {
                    document.querySelectorAll('.questions_group_body').forEach(function (el) {
                        el.classList.toggle('d-none')
                    });
                },
            }
        );
    }
}
