import { Controller } from "stimulus"
import StimulusReflex from "stimulus_reflex";
import $ from "jquery";

export default class extends Controller {
    static values  = {
        refresh: Number
    }

    connect() {
        this.element[this.identifier] = this
        StimulusReflex.register(this)
    }

    refreshValueChanged() {
        if (this.refreshValue === 1) {
            this.initialize_scroll_spy()
            this.refreshValue = 0
        }
    }

    initialize_scroll_spy() {
        $('.modal .navbar .nav-link').click(function (e) {
            $('.modal .nav').collapse('toggle');
        });

        this.element.addEventListener('shown.bs.modal', function (event) {
            new bootstrap.ScrollSpy(document.getElementById('scrollspy'), {
                target: '#preview-navbar'
            })
        })
    }
}
