import {Controller} from 'stimulus'
import StimulusReflex from 'stimulus_reflex';

export default class extends Controller {

  connect() {
    let controller = this
    this.element[this.identifier] = this
    StimulusReflex.register(controller)
    var el = document.getElementById('vital-item');
    var sortable = new Sortable(el, { swapThreshold: 1,
                                      ghostClass: 'drag',
                                      animation: 150,
                                      onEnd: this.processOrder(this) });
  }

  processOrder(context){
    return function() {
      let elements = document.getElementsByClassName('order_field')
      let res = []
      elements.forEach(e => res.push(e.value))
      const input = document.createElement('input');
      input.setAttribute('value', res);
      input.setAttribute('type', 'hidden');
      input.setAttribute('name', 'order_ids[]');
      elements[0].appendChild(input);

      context.stimulate('Backend::Admin::Vitals::CategoriesReflex#reorder', input);
    }
  }
}
