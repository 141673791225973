import {Controller} from 'stimulus'
import StimulusReflex from 'stimulus_reflex'
import Sortable from "sortablejs";
import AutoNumeric from 'autonumeric';

export default class extends Controller {
    static values = { event: String,
                      invoiceRecipientId: String,
                      refresh: Number,
                      merchant: String,
                      apikey: String,
                      validation: String }

    connect() {
      let controller = this
      this.element[this.identifier] = this
      this.numeric = AutoNumeric
      StimulusReflex.register(controller)
    }

    submit = (event) => {
      $('#submitButton').prop('disabled', true);
      event.preventDefault();
      if($("#flexSwitchCheckDefaultProcessCard").is(':checked')) {
        $( "#cardTokenInput" ).remove();
        let form = document.getElementById('payment-form');
        let tokenInput = document.createElement("input");
        tokenInput.setAttribute('name', 'card_token');
        tokenInput.setAttribute('type', 'hidden');
        tokenInput.setAttribute('id', 'cardTokenInput');
        tokenInput.setAttribute('value', 'validation');
        form.appendChild(tokenInput);
        this.stimulate(this.eventValue, form);
      }
      else {
        $( "#cardToken" ).remove();
        let form = document.getElementById('payment-form');
        this.stimulate(this.eventValue, form);
      }
    }

    refreshValueChanged() {
      if(this.refreshValue == 1) {
        this.refreshValue = 0;

        PayFields.reload()
        PayFields.config.apiKey = this.apikeyValue;
        PayFields.config.merchant = this.merchantValue;
        PayFields.config.mode = 'token'
        PayFields.fields = [{type: "number", element: "#number"},
                            {type: "cvv", element: "#cvv"},
                            {type: "expiration", element: "#expiration"}
                          ];
        PayFields.customizations.style = {
          '.input': {
            iconColor: '#666EE8',
            fontFamily: '"Source Sans Pro", sans-serif',
            display: 'block',
            width: 'calc(100% - 22px)',
            padding: '0.25rem 10px',
            fontSize: '1rem',
            lineHeight: '1.5rem',
            color: '#000',
            backgroundColor: '#fff',
            backgroundClip: 'padding-box',
            border: '1px solid #dbe6ec',
            borderRadius: '4px',
            transition: 'border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out',
            transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
            transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out'
          },

          '.card-icon': {
            top: '6px',
            right: '30px'
          },

          '.form-error': {
            marginTop: '.2rem',
            height: 'auto'
          }
        }
        PayFields.appendIframe()

        PayFields.onValidationFailure = () => {
          if(PayFields.fieldsErrors)
            $('#submitButton').prop('disabled', false);
        }
        PayFields.onFailure = function () {
          $('#submitButton').prop('disabled', false);
        }
        PayFields.customizations.placeholders = {
          '#cvv': 'cvv',
          '#expiration': 'mm / yy'
        }
        PayFields.onSuccess = response => {
          $('#submitButton').prop('disabled', true);
          let token = response.data[0].id;
          $( "#cardTokenInput" ).remove();
          let form = document.getElementById('payment-form');
          let tokenInput = document.createElement("input");
          tokenInput.setAttribute('name', 'card_token');
          tokenInput.setAttribute('type', 'hidden');
          tokenInput.setAttribute('id', 'cardTokenInput');
          tokenInput.setAttribute('value', token);
          form.appendChild(tokenInput);

          this.stimulate(this.eventValue, form)
        }
      }
    }

    validationValueChanged(){
      if(this.validationValue == 1) {
        $('#submitButton').prop('disabled', true);
        const amount = this.element.getAttribute('data-payrix-form-amount-cents-value')
        this.validationValue = 0;
        PayFields.config.amount = amount;
        PayFields.submit();
      }
    }
}
