import fetch from 'isomorphic-fetch'
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['details']

  connect() {
    this.paymentGatewayId = this.data.get('paymentGatewayId')
    this.fetchBankAccountDetails()
  }

  fetchBankAccountDetails() {
    fetch(`/payment_gateways/${this.paymentGatewayId}/banking_details`, {
      method: 'GET',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
      })
      .then(responseJson => {
        const bankAccount = responseJson.bank_account
        this.displayBankAccount(bankAccount)
      })
  }

  displayBankAccount(bankAccount) {
    let details = ''
    if (bankAccount.bank_name !== '' && bankAccount.bank_name !== null) {
      details = `${bankAccount.bank_name} - `
    }
    details += `Account Number: ****${bankAccount.last_four} / Routing Number: ${bankAccount.routing_number}`

    const account_fee_wrapper = document.getElementById('account_fee')
    if (bankAccount.account_fee && account_fee_wrapper) {
      document.getElementById('account_fee').innerText = bankAccount.account_fee
    }
    this.detailsTarget.textContent = details
  }
}
