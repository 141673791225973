import {Controller} from 'stimulus'
import CableReady from 'cable_ready'
import consumer from "../channels/consumer";

export default class extends Controller {
    static values = {
        channel: String,
        uuid: String,
        sgid: String,
    }

    connect() {
        let controller = this;
        if ((!this.hasUuidValue && !this.hasSgidValue) || !this.hasChannelValue) return;

        this.subscription = consumer.subscriptions.create(
            {
                channel: controller.channelValue,
                uuid: controller.hasUuidValue ? controller.uuidValue : null,
                sgid: controller.sgidValue,
            },
            {
                connected() {
                },
                disconnected() {
                },
                received(data) {
                    if (data.cableReady) CableReady.perform(data.operations)
                },
            },
        );
    }

    disconnect() {
        if ((!this.hasUuidValue && !this.hasSgidValue) || !this.hasChannelValue) return;

        this.subscription.unsubscribe();
    }
}
