import {Controller} from 'stimulus'

export default class extends Controller {
    connect() {
        this.element[this.identifier] = this
        new Sortable(
            this.element,
            {
                swapThreshold: 1,
                ghostClass: 'drag',
                animation: 150,
                group: {
                    name: 'shared',
                    pull: 'clone',
                    put: false,
                },
                sort: false
            }
        );
    }
}
