import { Controller } from "stimulus"
import Tribute from "tributejs"
import Trix from "../misc/trix";

export default class extends Controller {
  static targets = [ "field" ]

  connect() {
    this.editor = this.fieldTarget.editor
    this.initializeTribute()
  }

  disconnect() {
    this.tribute.detach(this.fieldTarget)
  }

  initializeTribute() {
    function remoteSearch(text, cb) {
      var URL = "/api/v1/users";
      let xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function() {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            var data = JSON.parse(xhr.responseText);
            cb(data);
          } else if (xhr.status === 403) {
            cb([]);
          }
        }
      };
      xhr.open("GET", URL + "?email=" + text, true);
      xhr.send();
    }
    this.tribute = new Tribute({
      allowSpaces: false,
      lookup: 'name',
      values: function (text, cb) {
        remoteSearch(text, users => cb(users));
      },
    })
    this.tribute.attach(this.fieldTarget)
    this.tribute.range.pasteHtml = this._pasteHtml.bind(this)
    this.fieldTarget.addEventListener("tribute-replaced", this.replaced)
  }

  replaced(e) {
    let mention = e.detail.item.original
    let attachment = new Trix.Attachment({
      sgid: mention.sgid,
      content: mention.content,
      contentType: 'application/html',
    })
    this.editor.insertAttachment(attachment)
    this.editor.insertString(" ")
  }

  _pasteHtml(html, startPos, endPos) {
    let position = this.editor.getPosition()
    this.editor.setSelectedRange([position  - (endPos - startPos), position])
    this.editor.deleteInDirection("backward")
  }
}
